// Code
//
// Inline and block-level code snippets. Includes tweaks to syntax highlighted
// snippets from Pygments/Rouge and Gist embeds.

code,
pre {
  font-family: $code-font-family;
}

code {
  background-color: $code-background;
  border-radius: 3px;
  color: $code-color;
  font-size: 85%;
  padding: 0.25em 0.5em;
}

pre {
  margin-bottom: 1rem;
  margin-top: 0;
  max-width: 100%;
  overflow-x: auto;
}

pre code {
  background-color: transparent;
  color: inherit;
  font-size: 100%;
  padding: 0;
}

// Pygments/Rouge via Jekyll
.highlight {
  background-color: $highlight-background;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  padding: 1rem;

  pre {
    margin-bottom: 0;
    overflow-x: auto;
  }

  .lineno {
    color: #999;
    display: inline-block; // Ensures the null space also isn't selectable
    padding-left: 0.25rem;
    padding-right: 0.75rem;
    user-select: none; // Make sure numbers aren't selectable
  }
}

// Gist via GitHub Pages
// .gist .gist-file {
//   font-family: Menlo, Monaco, "Courier New", monospace !important;
// }
// .gist .markdown-body {
//   padding: 15px;
// }
// .gist pre {
//   padding: 0;
//   background-color: transparent;
// }
// .gist .gist-file .gist-data {
//   font-size: .8rem !important;
//   line-height: 1.4;
// }
// .gist code {
//   padding: 0;
//   color: inherit;
//   background-color: transparent;
//   border-radius: 0;
// }
