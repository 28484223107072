$gray-1: #f9f9f9;
$gray-2: #e5e5e5;
$gray-3: #ccc;
$gray-4: #767676;
$gray-5: #515151;
$gray-6: #313131;

$base03:  #002b36;
$base02:  #073642;
$base01:  #586e75;
$base00:  #657b83;
$base0:   #839496;
$base1:   #93a1a1;
$base2:   #eee8d5;
$base3:   #fdf6e3;

$yellow:  #b58900;
$orange:  #cb4b16;
$red:     #dc322f;
$magenta: #d33682;
$violet:  #6c71c4;
$blue:    #268bd2;
$cyan:    #2aa198;
$green:   #859900;
$brown:   #8f5536;

$root-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
$root-font-size: 1rem;
$root-line-height: 1.5;

$body-color: $gray-5;
$body-muted: $gray-4;
$body-bg: $base3;
$link-color: $blue;
$heading-color: $gray-6;

$border-color: $gray-2;
$border-radius: 300px;
$padding-v: 1em;
$padding-h: 1.5em;
$heading-spacing: 0.5rem;
$section-spacing: 2rem;
$sidebar-width: 14rem; // 18

$large-breakpoint: 49rem;
$large-font-size: 1.25rem;

$box-shadow-size: 1px;
$box-shadow-opacity: 0.16;
$default-box-shadow: $box-shadow-size $box-shadow-size $box-shadow-size rgba(0, 0, 0, $box-shadow-opacity);

$code-font-family: Monaco, Menlo, 'Courier New', monospace;
$code-color: #bf616a;
$code-background: $base2;
$highlight-background: $base2;


// Hyde theming
$sidebar-bg-color: $base02; //202020
$sidebar-fg-color: #fff;
$sidebar-sticky: true;
$layout-reverse: false;

$sidebar-title-color: $sidebar-fg-color;
$sidebar-link-color: $sidebar-fg-color;
$sidebar-text-color: rgba($sidebar-fg-color, 0.75);
$sidebar-icon-color: rgba($sidebar-fg-color, 0.85);
// without the import, it breaks
@import "hydeout";
